import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { propertyApiPartnerUsers }) =>
    fromState.featureAdapter.setAll(propertyApiPartnerUsers, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.detachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.detachSuccess, (state, { userId }) =>
    fromState.featureAdapter.removeOne(userId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.detachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.attachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.attachSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.attachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function propertyApiPartnerUsersReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
