import { createAction, props } from '@ngrx/store';

import { IUserDetails } from '../../features/commons/user-details/models/user-detail.model';
import { PropertyUser } from '../../models';

export const loadRequest = createAction(
  '[Property Users] Load Request',
  props<{ propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Property Users] Load Success',
  props<{ propertyUsers: PropertyUser[] }>(),
);

export const loadFailure = createAction(
  '[Property Users] Load Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Property Users] Detach Request',
  props<{ propertyId: number; userId: number }>(),
);

export const detachSuccess = createAction(
  '[Property Users] Detach Success',
  props<{ userId: number }>(),
);

export const detachFailure = createAction(
  '[Property Users] Detach Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[Property Users] Attach Request',
  props<{
    propertyId: number;
    userId?: number;
    userData?: IUserDetails;
    roleId: number;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const attachSuccess = createAction('[Property Users] Attach Success');

export const attachFailure = createAction(
  '[Property Users] Attach Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Property Users] Reset State');
