import { createAction, props } from '@ngrx/store';

import { ApiPartnerUser } from '../../models';

export const loadRequest = createAction(
  '[Property Api Partner Users] Load Request',
  props<{ propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Property Api Partner Users] Load Success',
  props<{ propertyApiPartnerUsers: ApiPartnerUser[] }>(),
);

export const loadFailure = createAction(
  '[Property Api Partner Users] Load Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Property Api Partner Users] Detach Request',
  props<{ propertyId: number; userId: number }>(),
);

export const detachSuccess = createAction(
  '[Property Api Partner Users] Detach Success',
  props<{ userId: number }>(),
);

export const detachFailure = createAction(
  '[Property Api Partner Users] Detach Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[Property Api Partner Users] Attach Request',
  props<{ propertyId: number; userId: number }>(),
);

export const attachSuccess = createAction(
  '[Property Api Partner Users] Attach Success',
);

export const attachFailure = createAction(
  '[Property Api Partner Users] Attach Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Property Api Partner Users] Reset State',
);
