import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PropertyApiPartnerUsersService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PropertyApiPartnerUsersStoreEffects {
  constructor(
    private dataService: PropertyApiPartnerUsersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({ propertyApiPartnerUsers: response.data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ propertyId, userId }) =>
        this.dataService.detach(propertyId, userId).pipe(
          map(() => {
            this.notifications.done('user_detached');
            return fromActions.detachSuccess({ userId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.detachFailure(error));
          }),
        ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(({ propertyId, userId }) =>
        this.dataService.attach(propertyId, userId).pipe(
          mergeMap(() => {
            this.notifications.done('user_attached');
            return [
              fromActions.attachSuccess(),
              fromActions.loadRequest({ propertyId }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.attachFailure(error));
          }),
        ),
      ),
    ),
  );
}
