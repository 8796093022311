import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiPartnersLookupStoreEffects } from './effects';
import { apiPartnersLookupReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('apiPartnersLookup', apiPartnersLookupReducer),
    EffectsModule.forFeature([ApiPartnersLookupStoreEffects]),
  ],
})
export class ApiPartnersLookupStoreModule {}
