import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PropertyUsersService } from '../../services';
import { UserDetailsService } from '../../services/user-details.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PropertyUsersStoreEffects {
  constructor(
    private dataService: PropertyUsersService,
    private userService: UserDetailsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({ propertyUsers: response.data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ propertyId, userId }) =>
        this.dataService.detach(propertyId, userId).pipe(
          map(() => {
            this.notifications.done('user_detached');
            return fromActions.detachSuccess({ userId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.detachFailure(error));
          }),
        ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(
        ({ propertyId, userId, userData, roleId, onSuccess, onFailure }) => {
          let createdUserId: number;

          const userId$ = userId
            ? of(userId)
            : this.userService.create(userData).pipe(
                map(({ data }: IResponseSuccess) => {
                  createdUserId = data[0].id;
                  return createdUserId;
                }),
              );

          return userId$.pipe(
            switchMap((userIdToAttach) => {
              return this.dataService
                .attach(propertyId, userIdToAttach, roleId)
                .pipe(
                  mergeMap(() => {
                    this.notifications.done('user_attached');

                    if (onSuccess) {
                      onSuccess();
                    }

                    return [
                      fromActions.attachSuccess(),
                      fromActions.loadRequest({ propertyId }),
                    ];
                  }),
                  catchError((error) => {
                    if (onFailure) {
                      onFailure();
                    }

                    const onReturn = () => {
                      this.errorHandler.handle(error);
                      return of(fromActions.attachFailure(error));
                    };

                    if (createdUserId) {
                      return this.userService.delete(createdUserId).pipe(
                        switchMap(() => onReturn()),
                        catchError(() => onReturn()),
                      );
                    }

                    return onReturn();
                  }),
                );
            }),
          );
        },
      ),
    ),
  );
}
