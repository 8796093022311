import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { ApiPartner } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Api Partners Lookup] Load Request',
  props<{
    searchParams: SearchParams;
  }>(),
);

export const loadSuccess = createAction(
  '[Api Partners Lookup] Load Success',
  props<{ apiPartners: ApiPartner[] }>(),
);

export const loadFailure = createAction(
  '[Api Partners Lookup] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Api Partners Lookup] Reset State');
